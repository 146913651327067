<template>
  <div class="page form" v-loading="loading">
    <h2>文档</h2>

    <el-form label-width="100px">
      <el-form-item label="文档名">
        <el-select v-model="curr_key" @change="onChangeDocItem" placeholder="请选择文档">
          <el-option v-for="(content, key) in docs"
                     :key="key" :label="key" :value="key"/>
        </el-select>
      </el-form-item>

      <el-form-item label="内容">
        <div style="width: 600px">
          <text-editor v-model="curr_content"/>
        </div>
      </el-form-item>

      <el-form-item>
        <el-button type="primary" @click="onSubmit">提交</el-button>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>

  export default {
    data() {
      return {
        curr_key: '',
        curr_content: '',
        docs: {},
        loading: false,
        percentage: 0
      }
    },
    mounted() {
      this.load()
    },
    methods: {
      async load(v) {
        this.docs = await this.$api.request('/config_get', {name: 'doc'})
        window.scrollTo(0, 0)
      },
      onChangeDocItem(v) {
        this.curr_key = v
        this.curr_content = this.docs[v]
      },
      onSubmit() {
        this.$api.request('/config_set', {
          key: this.curr_key,
          value: this.curr_content,
          name: 'doc',
        }).then(() => {
          this.load()
          this.$message.success('更新成功!')
        })
      },
    }
  }
</script>
